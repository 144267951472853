* {
  margin: 0;
  padding: 0;
  font-family: Helvetica, Arial, sans-serif;
}

.app__header {
  margin-left: 1.5rem;
  margin-top: 2rem;
}

a {
  text-decoration: none;
  color: inherit;
}
