.summary-apartments-light {
  background-color: white;
  color: black;
}

.summary-apartments-dark {
  background-color: #121212;
  color: white;
}

.summary-container {
  max-width: 720px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
}

.summary-wrapper-light {
  background-color: white;
  border-radius: 20px;
  margin-bottom: 15px;

  box-shadow: rgba(0, 0, 0, 0.06) 0px 6px 20px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 2px;
  height: 480px;
}

.summary-wrapper-dark {
  border: 3px solid #1c1c1c;
  border-radius: 20px;
  margin-bottom: 15px;
  height: 480px;
}

.cashflow-container {
  height: 160px;
  background-color: #1f51ff;
  border-radius: 20px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
}

.cashflow__hd {
  text-align: center;
  color: white;
}

.cashflow__value {
  margin-top: 10px;
  text-align: center;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
}

.left__summary {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
}

.summary__header {
  margin: 15px;
}

.summary__header__1 {
  margin: 15px;
}

.summary__header__2 {
  margin-left: 15px;
  margin-top: 15px;
  margin-right: 15px;
  margin-bottom: 12px;
}

.summary-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  max-width: 500px;
}
.summary__label {
  margin-left: 15px;
  width: 150px;
}

.summary__value__row {
  width: 320px;
  text-align: right;
  font-weight: medium;
  margin-right: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 17px;
}

.summary__value__column {
  margin-left: 15px;
  width: 150px;
  margin-bottom: 10px;
  margin-top: 2px;
  font-size: 17px;
}

.apartment-number-row {
  display: flex;
  flex-direction: row;
  width: 250px;
  margin-left: auto;
  margin-right: auto;
  gap: 1.5em;
  margin-bottom: 10px;
}

.row-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 280px;
  gap: 4.5em;
}
.home-icon {
  color: #1f51ff;
  margin-top: 10px;
}
.add-icon {
  color: #1f51ff;
  margin-top: 12px;
}

.delete-icon {
  color: #1f51ff;
  margin-top: 12px;
  cursor: pointer;
}

.apartment-number {
  margin-top: 10px;
  font-weight: bold;
  font-size: 1.2rem;
}

.row-tax {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 15px;
}

.medium-br {
  display: block;
  margin-bottom: 1.2rem;
}

/* compare apartments cashflowRanking and rentalincomePercentage */

.compare-apartments {
  display: flex;
  max-width: 720px;
  background-color: white;

  border-radius: 20px;
  margin-bottom: 15px;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 6px 20px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 2px;
}

.compare-apartments-dark {
  display: flex;
  max-width: 720px;
  background-color: #121212;
  border-radius: 20px;
  margin-bottom: 15px;
  border: 3px solid #1c1c1c;
}

.bottom__summary {
  max-width: 720px;
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  color: black;
  border-radius: 20px;
  margin-top: 30px;
  height: 173px;
}

.bottom__summary__dark {
  max-width: 720px;
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  background-color: #121212;
  color: white;
  border-radius: 20px;
  margin-top: 30px;
  height: 167px;
}

.apartments-cost-renovation {
  width: 390px;
  height: 173px;
  border-radius: 20px;
  background-color: white;
  margin-right: 25px;

  box-shadow: rgba(0, 0, 0, 0.06) 0px 6px 20px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 2px;
}

.apartments-cost-renovation__dark {
  width: 390px;
  height: 167px;
  border-radius: 20px;
  background-color: #121212;
  margin-right: 25px;

  border: 3px solid #1c1c1c;
}

.apartments-cost-renovation__2 {
  width: 300px;
  height: 173px;
  border-radius: 20px;
  background-color: white;

  box-shadow: rgba(0, 0, 0, 0.06) 0px 6px 20px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 2px;
}
.apartments-cost-renovation__2__dark {
  width: 300px;
  height: 167px;
  border-radius: 20px;
  background-color: #121212;

  border: 3px solid #1c1c1c;
}

.summary-logo {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
  margin-top: 50px;
}

.summary-number {
  text-align: center;
  color: #1f51ff;
  font-size: 60px;
  font-weight: bold;
  margin-top: 20px;
}

@media only screen and (max-width: 739px) {
  .compare-apartments {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  .compare-apartments-dark {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    border: 3px solid #1c1c1c;
  }
}

@media only screen and (min-width: 1300px) {
  .summary-apartments-light {
    background-color: #f8f9fa;
  }
  .summary-container {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    max-width: 1300px;
  }
  .cashflow-container {
    display: flex;
    flex-direction: column;
  }

  .header-cashflow-month-year {
    display: flex;
    flex-direction: column;
    margin-right: 25px;
    margin-left: 15px;
  }

  .cashflow-container {
    margin-left: 0;
    margin-right: 0;
  }

  .summary__header {
    margin-left: 35px;
  }

  .summary-row {
    margin-left: 40px;
    margin-right: 40px;
  }

  .summary__label {
    margin-left: 0px;
  }

  .summary__value__row {
    margin-right: 0px;
    font-size: 18px;
  }

  .summary__value__column {
    margin-left: 40px;
    font-size: 18px;
  }

  .compare-apartments {
    /* background-color: hsl(0, 0%, 98%); */
    background-color: white;
  }
}
