.banner-light {
  color: black;
  object-fit: contain;
  height: 350px;
  background-image: url("/public/images/bannershowmobile.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-bottom: #f5f5f5 3px solid;
}

.banner-dark {
  color: black;
  object-fit: contain;
  height: 350px;
  background-image: url("/public/images/bannershowmobile.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  /* color: white;
  height: 350px;
  width: 100%;
  border-bottom: solid 3px #1c1c1c;
  background-color: #121212; */
  -webkit-filter: grayscale(1) invert(1);
  filter: grayscale(1) invert(1);
}

.banner-info {
  width: 360px;
  height: 350px;
  display: flex;
  flex-direction: column;
  margin-left: 0rem;
}

.banner__header {
  margin-top: 25px;
  margin-bottom: 2rem;
  text-align: left;
  margin-left: 1.5rem;
}

.banner__text {
  margin-bottom: 45px;
  font-size: 20px;
  text-align: left;
  margin-left: 1.5rem;
  max-width: 215px;
  font-style: normal;
}

.banner__btn {
  width: 100px;
  height: 40px;
  border-radius: 20px;
  background-color: #1f51ff;
  font-size: 1.1rem;
  color: white;
  font-weight: bold;
  border: none;
  margin-left: 1.5rem;
  cursor: pointer;
}

@media only screen and (max-width: 450px) {
  .banner-info {
    width: 300px;
  }
}
