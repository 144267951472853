.apartment-page-light {
  background-color: white;
  color: black;
}

.apartment-page-dark {
  background-color: #121212;
  color: white;
}

.apartment-page {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.apartment__image {
  max-width: 500px;
  height: 200px;
}

.row__apartment {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.info-container {
  line-height: 2;
  margin-left: 1rem;
}

.address__apartment {
  font-weight: bold;
  font-size: 1.3em;
  margin-top: 1.5rem;
  max-width: 290px;
}

.address__apartment__deleted {
  font-weight: bold;
  font-size: 1.3em;
  margin-top: 1.5rem;
  width: 270px;
  background-color: #1f51ff;
  height: 40px;
  color: white;
  text-align: center;
}

.bold__apartment {
  font-weight: bold;
  font-size: 1.1em;
  display: flex;
  flex-direction: row;
}

.bold__apartment__rent {
  color: #1f51ff;
  font-weight: bold;
  font-size: 1.5em;
  width: 180px;
}

.label__apartment {
  width: 180px;
}

.detail__apartment {
  width: 300px;
  text-align: right;
  margin-right: 10px;
}

.switch-row {
  display: flex;
  align-items: center;
}

.info__apartment {
  margin-right: 0.3rem;
  display: flex;
  flex-direction: row;
}

.info__apartment__m {
  margin-bottom: 2px;
}

.buttons-row {
  display: flex;
  flex-direction: row;

  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  margin-top: 1rem;
  max-width: 280px;
  gap: 4.5em;
}

.column__icon__text {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 11px;
  font-weight: bold;
  cursor: pointer;
}

.icon-symbol {
  margin-bottom: 10px;
  color: #1f51ff;
}

.icon-add-symbol {
  margin-bottom: 10px;
  color: #1f51ff;
}

.icon-delete-symbol {
  margin-bottom: 10px;
  color: #1f51ff;
  cursor: pointer;
}

.medium-br {
  display: block;
  margin-bottom: 1.5rem;
}
