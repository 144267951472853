.footer-light {
  background-color: white;
  color: black;
  border-top: solid 3px #f5f5f5;
}

.footer-dark {
  background-color: #121212;
  color: white;
  border-top: solid 3px #1c1c1c;
}

.footer-container {
  height: 340px;
  width: 100%;
}

.footer-logo-name {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
  margin-top: 30px;
  align-items: center;
}

.footer__header {
  font-size: 1.8rem;
}

.footer-logo {
  width: 90px;
  margin-right: 20px;
}

.footer-row {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
  margin-top: 30px;
}

.footer__links {
  display: flex;
  flex-direction: row;
  max-width: 300px;
}

.footer__link {
  text-decoration: none;
  margin-top: 5px;
  margin-right: 30px;
}

.footer-text {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
  margin-top: 30px;
  font-size: 14px;
}

.footer-text-copyright {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  max-width: 140px;
  margin-top: 30px;
}
