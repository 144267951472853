.add-form-light {
  width: 100%;
  background-color: white;
  color: black;
}

.add-form-dark {
  width: 100%;
  background-color: #121212;
  color: white;
}

.form-container {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin-top: 0px;
  margin-left: auto;
  margin-right: auto;
}

.add-new__hd {
  margin-top: 15px;
}

.form-more {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin-top: 5px;
  margin-left: 0px;
  margin-right: auto;
}

.btn__showall {
  color: #1f51ff;
  width: 120px;
  font-weight: bold;
  padding-bottom: 2px;
  border-bottom: 3px solid #1f51ff;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.input__label {
  text-align: left;
  margin-top: 1rem;
  font-size: 16px;
  margin-bottom: 5px;
}

.input__investment {
  width: 260px;
  height: 20px;
}

.input__investment.half {
  width: 150px;
}

.input__investment::placeholder {
  font-size: 16px;
}

.mandatory__text {
  margin-top: 5px;
  text-align: left;
}

.show-saved {
  margin-top: 25px;
  margin-bottom: 5px;
  font-size: 1em;
  font-weight: bold;

  background-color: #1f51ff;

  border-radius: 20px;
  border: none;
  color: white;
  height: 40px;
  width: 260px;
}

.clear__btn {
  background-color: #a9a9a9;
  border-radius: 20px;
  border: none;
  color: black;
  height: 30px;
  width: 120px;
  margin-top: 15px;
  margin-right: 20px;
  font-weight: bold;
  cursor: pointer;
}

.add__btn {
  background-color: #1f51ff;
  border-radius: 20px;
  border: none;
  color: white;
  height: 40px;
  width: 260px;
  margin-top: 30px;
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}

.row-radio {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* radio button*/
.radio__btn {
  margin-right: 10px;
}

/* decoration css */
.decoration__hd {
  margin-top: 20px;
}

.decoration-row {
  display: flex;
  flex-direction: row;
}

.squaremeter__label {
  margin-left: 10px;
}
