.homepage-light {
  background-color: white;
  color: black;
}

.homepage-dark {
  background-color: #121212;
  color: white;
}

.container-add-delete {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.add-new-apartment {
  width: 200px;
  height: 50px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  background-color: #1f51ff;
}

.add__text {
  color: white;
  font-weight: bold;
  text-align: right;
  font-size: 1.2rem;
  margin-left: 15px;
}

.homepage-delete-all {
  width: 70px;
  height: 50px;
  border-radius: 50px;
  display: flex;
  color: #1f51ff;
  align-items: center;
  font-weight: bold;
  font-size: 1.2rem;
  margin-left: 10px;
  cursor: pointer;
  margin-right: 10px;
}
