.navigation-light {
  background-color: white;
  color: black;
}

.navigation-dark {
  background-color: #121212;
  color: white;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
}
.logo-name-container {
  display: flex;
  align-items: center;
}
.website-name {
  font-size: 25px;
  font-weight: bold;
  margin-left: 10px;
}
nav .menu-logo {
  text-decoration: none;
  margin-left: 10px;
  max-width: 40px;
}

nav ul {
  display: flex;
  list-style-type: none;
}

nav ul li a {
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;

  margin-right: 50px;
  padding: 0.6em;
}

.light-dark-btn {
  margin-left: 4px;
  margin-right: 10px;
}

@media only screen and (max-width: 620px) {
  nav .menu-logo {
    margin-left: 10px;
    max-width: 50px;
  }
  .website-name {
    font-size: 20px;
    margin-left: 10px;
  }
  nav .menu-logo {
    margin-left: 10px;
  }
  nav ul li a {
    font-size: 15px;
    margin-right: 20px;
  }
  .menu-items {
    margin-top: 5px;
  }
}

@media only screen and (max-width: 450px) {
  nav .menu-logo {
    max-width: 45px;
  }
  .website-name {
    font-size: 15px;
    margin-left: 10px;
  }

  nav ul li a {
    font-size: 15px;
    margin-right: 8px;
  }
}

@media only screen and (max-width: 370px) {
  .website-name {
    font-size: 15px;
    margin-left: 10px;
  }

  nav ul li a {
    font-size: 14px;
    margin-right: 6px;
  }
}

@media only screen and (max-width: 355px) {
  .website-name {
    font-size: 12px;
  }
  nav ul li a {
    font-size: 12px;
  }
}
