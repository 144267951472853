.apartment-slide-light {
  width: 100%;
  min-height: 600px;
  background-color: white;
  color: black;
}

.apartment-slide-dark {
  width: 100%;
  min-height: 600px;
  background-color: #121212;
  color: white;
}

.apartment-grid {
  display: grid;
  gap: 0.1rem;
  grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
}

.apartment-container-light {
  max-width: 540px;
  height: 375px;
  box-shadow: 0 4px 12px rgb(0 0 0 / 0.4);
  margin: 1rem;
  border-radius: 20px;
}

.apartment-container-dark {
  max-width: 540px;
  height: 375px;
  /* box-shadow: 0 4px 12px rgba(210, 205, 205, 0.4); */
  border: 2px solid #1f51ff;
  margin: 1rem;
  border-radius: 20px;
}

.header-container {
  height: 60px;
  /*  background-color: #f6f6f6; */
  background-color: #1f51ff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.apartment__header {
  height: 30px;
  color: white;
  font-size: 1.2rem;
  padding: 1rem;
  max-width: 270px;
}

.row-apartment {
  display: flex;
  max-width: 490px;
  margin-top: 20px;
}
.apartment__label {
  margin-left: 15px;
  text-align: left;
  width: 150px;
}
.apartment__data {
  width: 150px;
  text-align: right;
  font-weight: bold;
  margin-right: 15px;
}

.row-apartment.column {
  display: flex;
  margin-top: 10px;
  align-items: center;
}

.apartment__data.column {
  margin-left: 15px;
  margin-top: 10px;
  text-align: left;
  max-width: 150px;
}

.apartment__rent__hd {
  margin-top: 10px;
  margin-left: 15px;
}

.apartment__rent {
  width: 230px;
  text-align: left;
  font-weight: bold;
  margin-top: 15px;
  margin-left: 15px;
  margin-bottom: 15px;
  font-size: 1.5rem;
  color: #1f51ff;
}

.show-apartment__btn {
  margin-left: 15px;
  height: 25px;
  width: 70px;
  background-color: white;
  border: 2px solid black;
  color: #1f51ff;
  border-radius: 15px;
  cursor: pointer;
  font-size: 14px;
}

.row-btn {
  display: flex;
  margin-top: 20px;
  margin-bottom: 5px;
}

.delete-apartment__btn {
  background-color: white;
  border: 2px solid black;
  color: #1f51ff;
  border-radius: 15px;
  margin-left: 15px;
  height: 25px;
  width: 70px;
  cursor: pointer;
  font-size: 14px;
}

@media only screen and (max-width: 800px) {
  .apartment-container-light {
    max-width: 300px;
  }

  .apartment-container-dark {
    max-width: 300px;
  }
  .apartment-grid {
    grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
  }
}

@media only screen and (max-width: 590px) {
  .apartment-container-light {
    max-width: 540px;
  }
  .apartment-container-dark {
    max-width: 540px;
  }
  .apartment-grid {
    grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
  }
}
