.page-not-found {
  height: 500px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  max-width: 310px;
  margin-top: 20px;
  color: black;
}

.pagenotfound__hd {
  margin-top: 40px;
  margin-bottom: 50px;
  font-size: 1.5rem;
  font-weight: bold;
}

.notfound-links {
  margin-top: 30px;
}

.notfound__link {
  text-align: right;
  margin-bottom: 10px;
  color: #1f51ff;
  font-weight: bold;
  text-decoration: underline;
}

.pagenotfound-logo {
  width: 110px;
}
